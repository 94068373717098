import React from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

import "./Home.css"

const items = [
    {
        src: '/images/home/slider/image1.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/images/home/slider/image2.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/images/home/slider/image3.jpg',
        altText: '',
        caption: ''
    }
];

class Home extends React.Component {
    constructor() {
        super();
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} alt={item.altText} width="100%" />
                    <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                </CarouselItem>
            );
        });

        return (
            <div id="homeMainContainer">

                <div id="sliderContainer">
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                    >
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </div>

                <div id="webPartsMainContainer" className='container'>
                    <div className='row webPartsContainers' >
                        <div className='col-12 col-md-6'>
                            <img alt="" src='/images/home/aichaLine.jpg' className='imgRadius' style={{ height: "300px", width: "100%", objectFit: "cover" }} />
                        </div>
                        <div className='col-12 col-md-6' style={{ position: "relative" }}>
                            <h3></h3>
                            <p>At Aicha Beauty Academy, we take pride in offering products that are carefully crafted using the finest ingredients and the latest beauty innovations. Our commitment to quality ensures that you can trust our products to deliver exceptional results every time. </p>
                            <a href="#/Products" style={{ color: "#000" }}><div class="arrow" /></a>
                        </div>
                    </div>
                    {/* <div className='row webPartsContainers'>
                        <img className='showOnlyOnMobile' alt="" src='/images/home/image2.jpg' style={{ height: "300px", width: "100%", objectFit: "cover" }} />
                        <div className='col-12 col-md-6' style={{ position: "relative" }}>
                            <h3>title</h3>
                            <p>text text text text text text </p>
                            <p>text text text </p>
                            <p>text text text text </p>
                            <p>text text text text text </p>
                        </div>
                        <div className='col-12 col-md-6'>
                            <img className='showOnlyOnPC' alt="" src='/images/home/image2.jpg' style={{ height: "300px", width: "100%", objectFit: "cover" }} />
                        </div>
                    </div>
                    <div className='row webPartsContainers' >
                        <div className='col-12 col-md-6'>
                            <img alt="" src='/images/home/image1.jpg' style={{ height: "250px", width: "100%", objectFit: "cover" }} />
                        </div>
                        <div className='col-12 col-md-6' style={{ position: "relative" }}>
                            <h3>title</h3>
                            <p>text text text text text text </p>
                            <p>text text text </p>
                            <p>text text text text </p>
                            <p>text text text text text </p>
                        </div>
                    </div> */}
                </div>
            </div >
        );
    }
}

export default Home;