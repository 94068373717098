import React from 'react'
import './ContactUs.css'


class ContactUs extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div id="contactUsMainContainer">
                <div className='container bigBodyContainer'>
                    <div id="contactUsContainer" className="row">
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <h5 style={{ fontSize: "22px", color: "#04423C", fontWeight: "bolder" }}><strong>Address</strong></h5>
                            <p style={{ fontSize: "20px", marginBottom: "0" }}>Amioun Al-koura</p>
                            <p style={{ fontSize: "20px", marginBottom: "0" }}>Main Road</p>
                            <p style={{ fontSize: "20px" }}>North Lebanon</p>

                            <h5 style={{ fontSize: "22px", color: "#04423C", fontWeight: "bolder" }}><strong>Phone</strong> </h5>
                            <a style={{ fontSize: "20px", marginBottom: "0" }} href="tel:+96170317175">+961 70 317 175</a>

                            <h5 style={{ fontSize: "22px", color: "#04423C", fontWeight: "bolder", marginTop: "15px" }} ><strong>Email Address</strong></h5>
                            <a style={{ fontSize: "20px", marginBottom: "0" }} href="mailto:info@AichaBeautyAcademy.com"> info@AichaBeautyAcademy.com</a>
                        </div>

                        <div className="col-12" style={{ textAlignLast: "center", marginTop: "30px" }}>
                            <iframe
                                width={window.innerWidth < 400 ? window.innerWidth - 100 : window.innerWidth < 600 ? window.innerWidth - 200 : window.innerWidth < 767 ? window.innerWidth - 250 : "630"} height="400"
                                title="Aicha Beauty Academy"
                                style={{ margin: "auto" }}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52717.289481030755!2d35.774193542919896!3d34.32933344227749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1521fb3d56772903%3A0x3dd3c2733ed525ed!2sAisha%20Beauty%20Center!5e0!3m2!1sen!2slb!4v1681300350491!5m2!1sen!2slb">
                            </iframe>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ContactUs;