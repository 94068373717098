import React from 'react'
import './AboutUs.css'


class AboutUs extends React.Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div id="aboutUsMainContainer">
                <div className='container bigBodyContainer'>
                    <div className='row'>
                        <div className='col-12' style={{ textAlignLast: "center", marginTop: "30px" }}>
                            {/* <iframe width={window.innerWidth < 767 ? window.innerWidth - 200 : "630"} height="402"
                                title="bassem Location"
                                style={{ margin: "auto" }}
                                src="https://www.youtube.com/embed/98gCJostAcI">
                            </iframe> */}

                            <video autoPlay={true} playsInline={true} loop={true} controls={true} id="anaEsmeAicha" >
                                <source src="/images/aboutUs/anaEsmeAicha.mp4" type="video/mp4" />
                            </video>

                        </div>
                        <div className='col-12' style={{ textAlign: "center", marginBottom: "30px" }}>
                            <h4>Introducing Aicha, the Visionary Founder of Aicha Beauty Academy</h4>
                            <p>Aicha, with a remarkable journey spanning over 15 years, embarked on her beauty odyssey within the serene confines of North Lebanon. Her passion and expertise have blossomed into a renowned establishment, specializing in nail care, captivating nail artistry, and distinguished skin care prowess.</p>
                            <p>In the year 2019, Aicha's dream materialized into a thriving reality, as her beauty haven came into existence. Since then, her influence and commitment have resonated far and wide, earning the unwavering trust of patrons spanning the breadth of North Lebanon, the bustling streets of Beirut, the charm of South Lebanon, and numerous other regions across Lebanon.</p>
                            <p>With her sights set on new horizons, Aicha now aspires to share her expertise and passion beyond borders, aiming to extend her presence to the esteemed Gulf Cooperation Council (GCC) region.</p>
                        </div>
                    </div>

                    <div id="aboutUSContainer" className='container'>
                        <div className='row aboutUSContainers' >
                            <div className='col-12 col-md-6'>
                                <img alt="" src='/images/aboutUs/image1.jpg' style={{ height: "250px", width: "100%", objectFit: "cover" }} />
                            </div>
                            <div className='col-12 col-md-6'>
                                <h3>Discover a World of Beauty Services at Aicha Beauty Academy</h3>
                                <p style={{marginBottom:"0"}}>
                                    At Aicha Beauty Academy, we are committed to helping you look and feel your best. Our beauty lounge offers an array of meticulously curated services designed to pamper and enhance your natural beauty. Our services include:
                                </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Manicure</p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Pose VernisPedicure </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Pedicure Gelish </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Eye Brows </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;MoustacheBrow Lamination </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Lash Extensions </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Lash Lamination </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Body Hair Removal </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Paraffin </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;GelishGel </p>
                                <p style={{marginBottom:"0"}}>•&nbsp;Gel Extensions </p>
                                <p>
                                    For our valued male clients, we offer tailored services that include manicures, pedicures, and eyebrow grooming, ensuring that everyone can enjoy a premium grooming experience at Aicha Beauty Academy.
                                </p>
                            </div>
                        </div>
                        <div className='row aboutUSContainers'>
                            <img className='showOnlyOnMobile' alt="" src='/images/aboutUs/image2.jpg' style={{ height: "250px", width: "100%", objectFit: "cover" }} />
                            <div className='col-12 col-md-6'>
                                <h3>Explore Our Diverse Range of Beauty Products</h3>
                                <p>At Aicha Beauty Academy, we take pride in offering an extensive array of beauty essentials, meticulously curated to cater to your every need.</p>
                                <p> Discover the world of beauty with us, both on our user-friendly website and within the welcoming embrace of our Beauty Lounge. Shop now to experience the finest in beauty and self-care.</p>
                            </div>
                            <div className='col-12 col-md-6'>
                                <img className='showOnlyOnPC' alt="" src='/images/aboutUs/image2.jpg' style={{ height: "250px", width: "100%", objectFit: "cover" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs;