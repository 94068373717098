import { find, map } from 'lodash';
import React from 'react'
import { Button, Carousel, CarouselControl, CarouselItem } from 'reactstrap';

import "./ProcuctsModalBody.css"
class ProcuctsModalBody extends React.Component {
    constructor() {
        super();
        this.state = {
            activeIndex: 0,
            selectedItem: {
                code: "",
                name: "",
                subName: "",
                description: "",

                itemKeys: [],

                codeName: "",
                relatedItemCodeName: [],
                size: "",
                price: "",

                ItemMainContainerSelected: "",
                selectedSize: "",
            },
            line: "",
            data: []
        };

        this.carouselItems = [];
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);


    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.carouselItems.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.carouselItems.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    componentDidMount = () => {
        let originalData = this.props.selectedData
        let line = this.props.selectedLine

        this.handleChangeItem(originalData[0].code)()

        this.setState({
            originalData,
            line,
        })
    }

    handlePriceChange = (value) => e => {
        this.carouselItems = []

        let selectedItem = {
            ...this.state.selectedItem,
            size: value.size,
            price: value.price,
        }

        map(value.relatedItemCodeName, (eachElem, key) => {
            let tempImgSource = "/images/products/items/" + this.props.selectedLine + "/" + eachElem + ".jpg"
            this.carouselItems.push(
                {
                    src: tempImgSource,
                    altText: this.props.selectedLine + key
                }
            )
        })

        this.setState({
            selectedItem,
            selectedSize: value.size
        })
    }

    handleChangeItem = value => e => {
        this.carouselItems = []

        let originalData = this.props.selectedData
        let tempItem = find(originalData, ['code', value])

        let tempSelectedsize = find(tempItem.itemKeys, ['size', this.state.selectedSize])
        let selectedItem = {
            code: tempItem.code,
            name: tempItem.name,
            subName: tempItem.subName,
            description: tempItem.description,

            itemKeys: tempItem.itemKeys,

            codeName: tempSelectedsize ? tempSelectedsize.codeName : tempItem.itemKeys[0].codeName,
            relatedItemCodeName: tempSelectedsize ? tempSelectedsize.relatedItemCodeName : tempItem.itemKeys[0].relatedItemCodeName,
            price: tempSelectedsize ? tempSelectedsize.price : tempItem.itemKeys[0].price,
            size: tempSelectedsize ? tempSelectedsize.size : tempItem.itemKeys[0].size,
            ItemMainContainerSelected: tempItem.code
        }
        let tempRelatedItemCodeName = tempSelectedsize ? tempSelectedsize.relatedItemCodeName : tempItem.itemKeys[0].relatedItemCodeName

        map(tempRelatedItemCodeName, (eachElem, key) => {
            let tempImgSource = "/images/products/items/" + this.props.selectedLine + "/" + eachElem + ".jpg"
            this.carouselItems.push(
                {
                    src: tempImgSource,
                    altText: this.props.selectedLine + key
                }
            )
        })

        this.setState({
            selectedItem,
        })
    }

    dohandleChangeItem = e => {
        console.log(this.state.selectedItem.itemKeys[0].size)
    }

    render() {
        const { activeIndex, selectedItem } = this.state;
        let order = this.state.selectedItem.itemKeys[0] ? this.state.selectedItem.name + this.state.selectedItem.code + " " + " " + this.state.selectedItem.subName + " " + (this.state.selectedItem.itemKeys[0].size !== "dontDisplay" ? this.state.selectedItem.itemKeys[0].size : "") + " " + this.state.selectedItem.itemKeys[0].price : ""
        return (

            <div id="ProcuctsModalBodyMainContainer" className='row'>
                <div className='productCardModal col-12'>
                    <div className='row'>

                        <div className='col-12 col-md-4'>
                            <Carousel
                                activeIndex={activeIndex}
                                next={this.next}
                                previous={this.previous}
                            >
                                {
                                    map(this.carouselItems, (carouselElement, key) => {
                                        return (
                                            <CarouselItem
                                                onExiting={this.onExiting}
                                                onExited={this.onExited}
                                                key={carouselElement + key}
                                            >
                                                <img src={carouselElement.src} alt={carouselElement.altText} width="100%" />
                                            </CarouselItem>
                                        );
                                    })
                                }
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        </div>

                        <div className='col-12 col-md-8' style={{ paddingRight: "0px" }}>
                            <div id="descMainContaine">
                                <h6><b>Name: &nbsp;</b></h6><p>{selectedItem.name + " " + selectedItem.subName}</p>
                                <div className='clearBoth'></div>
                                {selectedItem.size !== "dontDisplay" &&
                                    <>
                                        <h6><b>Size:&nbsp;</b></h6>
                                        {
                                            map(selectedItem.itemKeys, (eachIK, key) => {
                                                return (
                                                    <Button
                                                        key={"button-" + key}
                                                        onClick={this.handlePriceChange(eachIK)}
                                                        className={selectedItem.size === eachIK.size ? "btnSize btnSizeSelected" : "btnSize"}
                                                    ><p>{eachIK.size}</p></Button>
                                                )
                                            })
                                        }
                                    </>
                                }
                                <div className='clearBoth'></div>

                                <h6><b>Price: &nbsp;</b></h6><p> {selectedItem.price}</p>
                                <div className='clearBoth'></div>

                                {selectedItem.description !== "dontDisplay" &&
                                    <>
                                        <h6 style={{ marginBottom: 0 }}><b>Description:&nbsp;</b></h6><p> {selectedItem.description}</p>
                                    </>
                                }
                                <div className='clearBoth'></div>

                            </div>
                            <div style={{ maxHeight: "300px", overflow: "auto" }}>
                                {
                                    map(this.state.originalData, (eachElem, key) => {
                                        let tempImgSource = ""
                                        if (this.state.line !== "nailPolish") {
                                            tempImgSource = "/images/products/items/" + this.state.line + "/" + eachElem.itemKeys[0].codeName + ".jpg"
                                        } else {
                                            tempImgSource = "/images/products/items/" + this.state.line + "/Solid/" + eachElem.itemKeys[0].codeName + ".jpg"
                                        }
                                        return (
                                            <div key={eachElem.code + key} className={selectedItem.ItemMainContainerSelected === eachElem.code ? "ItemMainContainer ItemMainContainerSelected" : "ItemMainContainer"} onClick={this.handleChangeItem(eachElem.code)}>
                                                <img alt=""
                                                    className={selectedItem.ItemMainContainerSelected === eachElem.code ? "ItemImg ItemImgSelected" : "ItemImg"}
                                                    style={this.state.line === "nailPolish" && eachElem.code !== "NailPolishCoatTop" ? { margin: "-1px 0px 0px -1px", width: "76px" } : {}} src={tempImgSource} />
                                                <div className="ItemDescContainer">
                                                    <div className="ItemDesc">{eachElem.subName}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Button className='whatsappBtn' color="success">
                                {/* this.state.selectedItem.itemKeys[0].codeName + this.state.selectedItem.itemKeys[0].size + this.state.selectedItem.itemKeys[0].price + */}
                                <a onClick={this.dohandleChangeItem} style={{ color: "white", textDecoration: "none" }}
                                    href={this.state.selectedItem.itemKeys ?
                                        "https://api.whatsapp.com/send?phone=96170317175&text=" + order : ""} target="_blank" rel="noreferrer" >
                                    <img src={"/images/whatsapp.png"} alt={"whatsapp"} height="30px" width="30px" />Place Order</a>
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default ProcuctsModalBody;