import React from 'react'
import { Outlet } from 'react-router-dom';
import HeaderMenu from '../headerMenu/HeaderMenu';
import Footer from '../footer/Footer';

class Full extends React.Component {
    constructor() {
        super();
        this.state = {
            showMobileMenu: false,
        }
    }

    render() {
        let { showMobileMenu } = this.state
        return (
            <>
                <div className="App" >
                    <HeaderMenu
                        id="main-header-container"
                        mobileMenu={true}
                        showMobileMenu={showMobileMenu}
                    />
                    <div id="app-body">
                        <main id="main" >
                            <div className="container-fluid">
                                <Outlet />
                            </div>
                        </main>
                    </div>
                                <Footer />
                </div>
            </>
        )
    }
}



export default Full;
