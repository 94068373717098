import { find, isEmpty, map } from 'lodash';
import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ProcuctsModalBody from './procuctsModalBody/ProcuctsModalBody';

import "./Products.css"

class Products extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedLine: "",
            selectedData: [],
            modalTitle: "",
            data: {},
            lineData: [],
        }
    }

    componentDidMount = async () => {
        let tempData = {}
        let tempLineData = []
        await fetch("data.json").then(
            function (res) {
                return res.json()
            }).then(function (data) {
                tempData = data.data
                tempLineData = data.lineData
            }).catch(
                function (err) {
                    console.log(err, ' error')
                }
            )
        this.setState({
            data:tempData,
            lineData:tempLineData
        })
    }

    toggle = value => e => {
        let selectedLine = ""
        let selectedData = []
        let modalTitle = ""

        if (!isEmpty(value)) {
            selectedLine = value
            modalTitle = find(this.state.lineData, ['modalName', value]).modalTitle
            selectedData = this.state.data[value]
        }

        this.setState({
            selectedLine,
            selectedData,
            modalTitle,
        });
    }

    render() {
        return (
            <div id="productsMainContainer">
                <div className='container bigBodyContainer'>
                    <div className='row' style={{ padding: "10px" }}>
                        {map(this.state.lineData, (eachElem, key) => {
                            return (
                                <div className='productCard col-12' key={key}>
                                    <div className='row' onClick={this.toggle(eachElem.modalName)}>
                                        <div className='col-12 col-md-4'>
                                            <img alt="" src={eachElem.cardImage}  className='imgRadius' style={{ height: "150px", width: "100%", objectFit: "cover" }} />
                                        </div>
                                        <div className='col-12 col-md-8'>
                                            <h5 style={window.innerWidth<767?{paddingTop:"10px"}:{}}>{eachElem.cardTitle}</h5>
                                            <h6>{eachElem.cardSubtitle}</h6>
                                            <p style={{color:"grey"}}>{eachElem.cardText}</p>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <Modal isOpen={!isEmpty(this.state.selectedLine)} toggle={this.toggle("")} size="xl">
                        <ModalHeader toggle={this.toggle("")}><b>{this.state.modalTitle}</b></ModalHeader>
                        <ModalBody>
                            <ProcuctsModalBody
                                selectedLine={this.state.selectedLine}
                                selectedData={this.state.selectedData}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggle("")}>Close</Button>
                        </ModalFooter>
                    </Modal>

                </div>
            </div>
        )
    }
}

export default Products;
