import React, { useCallback, useState } from 'react'
import { Nav, Navbar, NavbarToggler, Collapse, NavItem, NavLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

export function HeaderMenu() {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen]);

    const goToPage = useCallback((url) => e => {
        if (window.innerWidth < 768)
            toggle()

        navigate(url)
    }, [navigate, toggle]);

    return (
            <Navbar color="light" light expand="md" >

                <NavLink style={{marginLeft:"20px"}}  onClick={goToPage("Home")} >
              <img alt="" src='/images/logo.png' style={{ height: "60px", objectFit: "contain" }} />
                </NavLink>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav style={{ marginLeft: "auto" }} navbar>
                        <NavItem>
                            <NavLink onClick={goToPage("Home")}><b>Home</b></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={goToPage("Products")}><b>Products</b></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={goToPage("AboutUs")}><b>About Us</b></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={goToPage("ContactUs")}><b>Contact Us</b></NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink onClick={goToPage("AnyPage")}>AnyPage</NavLink>
                        </NavItem> */}
                    </Nav>
                </Collapse>
            </Navbar>
    )
}

export default HeaderMenu;
