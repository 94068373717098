import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './AppBodyComponents/aboutUs/AboutUs';
import ContactUs from './AppBodyComponents/contactUs/ContactUs';
import Home from './AppBodyComponents/home/Home';
import Products from './AppBodyComponents/products/Products';
// import AnyPage from './AppMainComponents/AppPages/AnyPage';
import Full from './AppMainComponents/full/Full';

function App() {

  return (
<>
{/* <img alt="" src='/images/underConstruction.jpg' style={{ height: "auto", width: "100%", objectFit: "cover" }} /> */}
    <Routes>
      <Route path='/' element={<Full />}>
        <Route path='/' element={<Home />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/Products' element={<Products />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/ContactUs' element={<ContactUs />} />
      </Route>
    </Routes >
</>
  );
}


export default App;
